<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    attachModule="tomografiaDeCorneaPentacam"
    :hasExam="hasAttendanceValues('exam')"
    @getData="simpleImportData(null, $event)"
  >
  <b-row>
    <b-col>  
      <b-row>
        <b-col cols="6">
          <div class="form-group mb-0">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> D</p>
              <input
                autocomplete="off"
                :value="form.fields.olhoDireito"
                :readonly="!canEdit"
                @input="el => updateForm('olhoDireito', el.target.value)"
                @blur="updateMedicalRecord(form)"
                id="tomografiaDeCorneaPentacam-direito"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="form-group mb-0">
            <div class="input-eye">
              <p class="tag-eye"><EyeFill /> E</p>
              <input
                autocomplete="off"
                :value="form.fields.olhoEsquerdo"
                :readonly="!canEdit"
                @input="el => updateForm('olhoEsquerdo', el.target.value)"
                @blur="updateMedicalRecord(form)"
                id="tomografiaDeCorneaPentacam-esquerdo"
                type="text"
                class="form-control"
                placeholder="Resultado"
              />
            </div>
          </div>
        </b-col>

      </b-row>
    </b-col>
    <button
      class="btn blue-underline button-copy center"
      @click="copyEyeValues"
      v-if="canEdit"
      v-b-tooltip.hover title="Copiar valores O.D para O.E"
    >
      <Copy />
    </button>
  </b-row>    
  </FormWrapper>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'

export default {
  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    Copy: () => import('@/assets/icons/copy.svg'),
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.tomografiaDeCorneaPentacam,
      medicalRecordsStore: state => state.attendance.medicalRecordsStore
    }),
    ...mapGetters('attendance', ['exam'])
  },
  mixins: [attendanceDataMixin('medicalRecordsStore')],
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/tomografiaDeCorneaPentacam', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    copyEyeValues() {
      this.updateForm('olhoEsquerdo', this.form.fields.olhoDireito)
      setTimeout(() => { this.updateMedicalRecord(this.form) }, 500)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    }
  }
}
</script>
